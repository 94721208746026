import { getProductFamilyListApi } from "api/products.api";
import { getFromLocalStorage } from "helpers/localstorage";
import { useEffect, useState } from "react";

type Family = {
  id: string;
  name: string;
};

function useCachedFamilies() {
  const [families, setFamilies] = useState<Family[]>([]);
  const agencyId = getFromLocalStorage()?.agencyId;

  useEffect(() => {
    async function getData() {
      const cachedFamilies = JSON.parse(
        sessionStorage.getItem("families") ?? "[]"
      );
      if (cachedFamilies.length > 0) {
        setFamilies(cachedFamilies);
        return;
      }

      const { body } = await getProductFamilyListApi(agencyId);
      setFamilies([...body]);
      sessionStorage.setItem("families", JSON.stringify([...body]));
    }

    getData();

    return () => {
      setFamilies([]);
    };
  }, []);

  return {
    families,
  };
}

export default useCachedFamilies;
