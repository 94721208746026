import Empty from "../layouts/app/app.layout";
import React, { useCallback } from "react";
import { Button, Grid } from "@mui/material";
import { logoutApi } from "../api/auth.api";
import { useLanguage } from "../locale/useLanguage";
import { Logo } from "../components/Logo/Logo";

type GeneralErrorProps = {};

const GeneralError: React.FC<GeneralErrorProps> = () => {
  const [t] = useLanguage();
  const close = useCallback(() => {
    logoutApi();
    return;
  }, []);
  return (
    <Empty>
      <Grid container className="general-error--container">
        <Grid item xs={12} container justifyContent="center" alignContent="flex-end">
          <Logo className="general-error--image" />
        </Grid>
        <Grid className="general-error--content">
          <h2>{t("generalError.title")}</h2>
          <p>{t("generalError.text")}</p>
        </Grid>
        <Grid className="general-error--exit-container">
          <Grid className="general-error--exit-button">
            <Button onClick={close}>{t("generalError.close")}</Button>
          </Grid>
        </Grid>
      </Grid>
    </Empty>
  );
};

export default GeneralError;
