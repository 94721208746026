const defaultLSKey = 'general';

/**
 * Gets info in LocalStorage (supports JSON)
 *
 * @author davide.mantovani
 *
 * @param {String} key identifier of a portion of LocalStorage (default "general")
 *
 * @returns {JSON} This method returns the information if found (in JSON or in ANY as a fallback), or undefined if null
 */
export const getFromLocalStorage = (key = defaultLSKey) => {
  const data = localStorage.getItem(key);
  if (!data) return undefined;
  try {
    return JSON.parse(data);
  } catch (e) {
    return data;
  }
};

/**
 * Saves info in LocalStorage (supports JSON)
 *
 * @author davide.mantovani
 *
 * @param {*} data information to be stored in LocalStorage
 * @param {String} key identifier of a portion of LocalStorage (default "general")
 */
export const setOnLocalStorage = (data = {}, key = defaultLSKey) => {
  let obj = { data };
  const newData = {
    ...getFromLocalStorage(key),
    ...(data.constructor === Object ? data : obj),
  };
  localStorage.setItem(key, JSON.stringify(newData));
};

/**
 * Removes data from LocalStorage (supports JSON)
 *
 * @author davide.mantovani
 *
 * @param {*} val information to be removed in LocalStorage (eg. a JSON key)
 * @param {String} key identifier of a portion of LocalStorage (default "general")
 */
export const removeFromLocalStorage = (val?: string, key = defaultLSKey) => {
  if (val) {
    let newData = getFromLocalStorage(key);
    if (newData) {
      delete newData[val];
      if (Object.keys(newData).length > 0) {
        localStorage.setItem(key, JSON.stringify(newData));
      } else {
        localStorage.removeItem(key);
      }
    }
  } else {
    localStorage.removeItem(key);
  }
};
