import React, { ReactElement, useState } from "react";
import AppHeaderAdmin from "../../components/Header/AdminHeader";
import Box from "@mui/material/Box";
import { Button, Grid } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import MetaTags from "../../components/MetaTags/MetaTags";
import { routes } from "../../routes/routes";
import { useHistory } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";
import { useLanguage } from "../../locale/useLanguage";
import "./products.styles.scss";
import DrawerNavbar from "components/DrawerNavbar/DrawerNavbar";

const ProductLayout: React.FC<{
  title: string;
  hideOnBack?: boolean;
  children?: ReactElement;
}> = ({ children, title = "", hideOnBack = false }) => {
  const history = useHistory();
  const [t] = useLanguage();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  return (
    <>
      <MetaTags title={title} />
      <div className="admin-layout">
        <CssBaseline />
        <AppHeaderAdmin
          menu={routes}
          openDrawerAction={() => {
            setIsDrawerOpen(!isDrawerOpen);
          }}
        />
        <DrawerNavbar
          open={isDrawerOpen}
          onClose={() => setIsDrawerOpen(false)}
        />
        <Box component="main" className="admin-layout-box-container">
          <Grid
            container
            className="system-page"
            alignContent="start"
            style={{ paddingInline: 0 }}
          >
            {!hideOnBack && (
              <Grid item container xs={12} sx={{ height: "64px" }}>
                <Button
                  className="mt-2"
                  style={{ fontSize: "1.3rem" }}
                  startIcon={<ArrowBack />}
                  onClick={() => history.goBack()}
                >
                  {t("common.go_back")}
                </Button>
              </Grid>
            )}

            {children}
          </Grid>
        </Box>
      </div>
    </>
  );
};
export default ProductLayout;
