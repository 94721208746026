import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";

type CloseDrawerButtonProps = {
  onClick?: () => void;
};

function CloseDrawerButton(props: CloseDrawerButtonProps) {
  const { onClick } = props;

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "end",
        paddingRight: "0.5rem",
        paddingTop: "0.5rem",
      }}
      onClick={onClick}
    >
      <IconButton disableRipple disableFocusRipple disableTouchRipple>
        <CloseIcon />
      </IconButton>
    </div>
  );
}

export default CloseDrawerButton;
