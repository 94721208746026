import { useCallback, useEffect, useState } from "react";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import { styled } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import { ArrowDropDown, PowerSettingsNew } from "@mui/icons-material";
import { useLanguage } from "../../locale/useLanguage";
import { Logo } from "../Logo/Logo";
import { RouteType } from "../Router/Router";
import { Grid, IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import IconButtonMenu from "../IconButtonMenu/IconButtonMenu";
import "./AdminHeader.scss";
import {
  getFromLocalStorage,
  removeFromLocalStorage,
  setOnLocalStorage,
} from "../../helpers/localstorage";
import { getCookie, setCookie } from "../../helpers/cookie";
import { myAgency } from "../../api/auth.api";
import Loading from "../Loading/Loading";
import { RoleEnum } from "../../models/role.types";
import {
  CallbackTranslation,
  TranslationKeys,
} from "locale/localizationContext";
import MenuIcon from "@mui/icons-material/Menu";

const drawerWidth = 240;
interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

function findMatchingRoute(currentPath: string, routes: RouteType[]) {
  for (const route of routes) {
    const routeRegex = new RegExp(
      `^${route.path.replace(/:[^/]+/g, "([^/]+)")}$`,
    );
    if (routeRegex.test(currentPath)) {
      return route;
    }
  }

  return null;
}

function getNavbarTitleAndSubtitle(
  currentPath: string,
  routes: RouteType[],
  t: CallbackTranslation,
): [string, string] {
  const route = findMatchingRoute(currentPath, routes);
  if (!route) return ["", ""];

  const pathParts = currentPath.split("/").filter((part) => part !== "");
  if (pathParts.length === 1) {
    return [t(route.titleLocalizationKey as TranslationKeys<string>), ""];
  }

  if (route.id === "stats") {
    const category = pathParts[1];
    const categoryLocalizationKey = `pages.stats.stats_type.${category}.title`;

    if (pathParts.length === 2) {
      return [
        t(categoryLocalizationKey as TranslationKeys<string>),
        t("pages.stats.pages.type_selection.section"),
      ];
    }

    if (pathParts.length === 3) {
      const id = pathParts[2];
      const idLocalizationKey = `pages.stats.stats_type.${category}.${id}`;
      return [
        t(categoryLocalizationKey as TranslationKeys<string>),
        t(idLocalizationKey as TranslationKeys<string>),
      ];
    }
  }

  if (route.id === "products") {
    const section = pathParts[1];
    const families = JSON.parse(sessionStorage.getItem("families") || "[]");
    const sectionTitle = families.find((f: any) => f.id === section)?.name;

    if (pathParts.length === 2) {
      return [
        sectionTitle ?? section,
        t("pages.products.pages.model_selection.step_name"),
      ];
    }

    if (pathParts.length === 3) {
      const id = pathParts[2];
      const idTitle = id.replace(/_/g, " ");
      return [sectionTitle, idTitle];
    }
  }

  if (route.id === "cart") {
    const title = t(route.titleLocalizationKey as TranslationKeys<string>);
    const subtitle = t(
      `pages.cart.pages.${pathParts[1]}.title` as TranslationKeys<string>,
    );

    return [title, subtitle];
  }

  return [t(route.titleLocalizationKey as TranslationKeys<string>), ""];
}

const AppHeaderAdmin = ({
  open,
  openDrawerAction,
  bigHeader,
  menu,
}: {
  open?: boolean;
  openDrawerAction?: any;
  bigHeader?: boolean;
  menu?: Array<RouteType>;
}) => {
  const [loading, setLoading] = useState(false);
  const role = getCookie("role");
  const { agencyName, username } = (getFromLocalStorage() as any) || {};
  const [agencies, setAgencies] = useState<Array<any>>([]);
  const [t] = useLanguage();
  const [title, subtitle] = getNavbarTitleAndSubtitle(
    window.location.pathname,
    menu || [],
    t,
  );

  useEffect(() => {
    const getAgencies = async () => {
      setLoading(true);
      const { payload, body } = await myAgency();
      if (payload.ok && body) {
        setAgencies(body.agencies);
      }
      setLoading(false);
    };
    username && getAgencies();
  }, [username]);

  const displayChangeAgencies =
    agencies.length > 1 &&
    role !== RoleEnum.SUPERADMINISTRATOR &&
    role !== RoleEnum.ADMINISTRATOR;

  const logout = useCallback(async () => {
    setCookie("token", "", -1);
    setCookie("role", "");
    removeFromLocalStorage("agencyId");
    removeFromLocalStorage("agencyName");
    removeFromLocalStorage("username");
    removeFromLocalStorage("shippingUnitCost");
    window.location.href = "/login";
  }, []);

  return (
    <>
      <AppBar position="fixed" open={open} className="background-none">
        <Toolbar className={bigHeader ? "admin-header-big" : "admin-header"}>
          <Grid
            container
            justifyContent="space-between"
            sx={{ height: "100%" }}
            rowGap={1}
          >
            {username !== undefined && agencyName !== undefined ? (
              <Grid
                item
                container
                xs={1}
                md={0.2}
                lg={0.1}
                alignContent="center"
              >
                <IconButton onClick={openDrawerAction}>
                  <MenuIcon />
                </IconButton>
              </Grid>
            ) : null}
            <Grid
              item
              container
              xs={5}
              md={2}
              alignItems="center"
              flexDirection="row"
            >
              <Link to={"/"} style={{ height: "42px" }}>
                <Logo />
              </Link>
              <p style={{ fontSize: 12, alignSelf: "self-end" }}>
                powered by abetterental
              </p>
            </Grid>
            <Grid
              item
              container
              md={displayChangeAgencies ? 5 : 6}
              lg={6}
              sx={{
                display: { xs: "none", sm: "none", md: "flex", lg: "flex" },
                pl: 9,
              }}
              justifyContent="center"
              className={`navbar-page-title ${
                (title + " | " + subtitle).length >= 50 ? "long-text" : ""
              }`}
            >
              <div
                style={{
                  display: "flex",
                  columnGap: "10px",
                  flexWrap: "wrap",
                  justifyContent: "center",
                }}
              >
                {title && <span>{title}</span>}
                {subtitle && <span>|</span>}
                {subtitle && <span>{subtitle}</span>}
              </div>
            </Grid>
            <Grid
              item
              container
              xs={6}
              md={displayChangeAgencies ? 4 : 3}
              lg={3}
              alignContent="center"
            >
              {agencyName && (
                <Grid item container xs={12}>
                  {displayChangeAgencies ? (
                    <>
                      <Grid
                        item
                        container
                        xs={6}
                        justifyContent="right"
                        alignItems="center"
                      >
                        <Grid item container xs={12} justifyContent="right">
                          <b>Cambia agenzia</b>
                        </Grid>
                        <small>{agencyName}</small>
                      </Grid>
                      <Grid item container xs={2} alignContent="center">
                        <IconButtonMenu
                          className="py-0"
                          icon={<ArrowDropDown />}
                          listItemMenu={agencies.map((a) => {
                            return {
                              label: a.agency.name,
                              onClick: async () => {
                                setCookie("role", a.role);
                                setOnLocalStorage({
                                  agencyId: a.agency.id,
                                  shippingUnitCost: a.agency.shippingUnitCost,
                                  shippingMaxUnits: a.agency.shippingMaxUnits,
                                  agencyName: a.agency.name,
                                });
                                window.location.reload();
                              },
                            };
                          })}
                        />
                      </Grid>
                    </>
                  ) : null}
                  <Grid
                    item
                    container
                    xs={displayChangeAgencies ? 2 : 10}
                    justifyContent="right"
                    alignItems="center"
                  >
                    <Grid item container xs={12} justifyContent="right">
                      <b>{username}</b>
                    </Grid>
                    <small>{agencyName}</small>
                  </Grid>
                  <Grid item container xs={2} alignContent="center">
                    <IconButtonMenu
                      className="py-0"
                      icon={<ArrowDropDown />}
                      listItemMenu={[
                        {
                          icons: [<PowerSettingsNew />],
                          label: t("common.logout"),
                          onClick: logout,
                        },
                      ]}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {loading && <Loading />}
    </>
  );
};
export default AppHeaderAdmin;
