import dayjs from "dayjs";
import Config from "../Config";
import { Api } from "../helpers/api";
import { getCookie, setCookie } from "../helpers/cookie";
import { parseJwt } from "../helpers/jwt";
import { getFromLocalStorage, removeFromLocalStorage, setOnLocalStorage } from "../helpers/localstorage";
import { refreshTokenApi } from "./auth.api";
// import firebase from "firebaseApp";

export const api = new Api(Config.apiBaseurl);

export const customSecuredApi = new Api(
  Config.apiBaseurl,
  async ({ headers = new Headers() }) => {
    const token = getCookie("token");
    const callToken = headers?.get("authorization");
    if (!!callToken && (callToken || "").replace("Bearer ", "") !== token) {
      return;
    } else {
      if (token) {
        const { exp = 0 } = parseJwt(token) || {};
        const nowTime = dayjs();
        const expTime = dayjs(exp * 1000);
        if (nowTime.isAfter(expTime)) {
          setCookie("token", "");
          setCookie("role", "");
          setOnLocalStorage({
            agencyId: undefined,
            shippingUnitCost: undefined,
            agencyName: undefined,
            shippingMaxUnits: undefined,
            username: undefined,
          });
          window.location.href = "/login?error=expired_session";
        } else {
          headers.append("authorization", `Bearer ${token}`);
          headers.append("Access-Control-Allow-Origin", Config.apiBaseurl);
          return { headers };
        }
      } else {
        return;
      }
    }
  },
  (error) => {
    if (error.getStatus() === 401) {
      setCookie("token", "");
      setCookie("role", "");
      setOnLocalStorage({
        agencyId: undefined,
        shippingUnitCost: undefined,
        agencyName: undefined,
        shippingMaxUnits: undefined,
        username: undefined,
      });
      if (!window.location.href.includes("expired_session")) {
        window.location.href = "/login?error=unauthorized";
      }
    }
  }
);

export const customSecuredImportFileApi = new Api(
  Config.apiBaseurl,
  async ({ headers = new Headers() }) => {
    const token = getCookie("token");
    const callToken = headers?.get("authorization");
    if (!!callToken && (callToken || "").replace("Bearer ", "") !== token) {
      return;
    } else {
      if (token) {
        const { exp = 0 } = parseJwt(token) || {};
        const nowTime = dayjs();
        const expTime = dayjs(exp * 1000);
        if (nowTime.isAfter(expTime)) {
          setCookie("token", "");
          setOnLocalStorage({
            agencyId: undefined,
            role: undefined,
            shippingUnitCost: undefined,
            agencyName: undefined,
            shippingMaxUnits: undefined,
          });
          window.location.href = "/login?error=expired_session";
        } else {
          headers.append("authorization", `Bearer ${token}`);
          return { headers };
        }
      } else {
        return;
      }
    }
  },
  (error) => {
    if (error.getStatus() === 401) {
      setCookie("token", "");
      setOnLocalStorage({
        agencyId: undefined,
        role: undefined,
        shippingUnitCost: undefined,
        agencyName: undefined,
        shippingMaxUnits: undefined,
      });
      if (!window.location.href.includes("expired_session")) {
        window.location.href = "/login?error=unauthorized";
      }
    }
  }
);

export const securedApi = new Api(
  Config.apiBaseurl,
  async ({ headers = new Headers() }) => {
    const { token } = getFromLocalStorage() || {};
    const refreshToken = getCookie("refreshToken");
    const callToken = headers?.get("authorization");
    const isRefreshing = getFromLocalStorage("refreshing");
    if (!!callToken && (callToken || "").replace("Bearer ", "") !== token) {
      return;
    } else if (refreshToken && !isRefreshing) {
      setOnLocalStorage(true, "refreshing");
      try {
        const { exp = 0 } = parseJwt(token) || {};
        const nowTime = dayjs();
        const expTime = dayjs(exp * 1000).subtract(10, "minutes");
        if (nowTime.isAfter(expTime)) {
          const { token: newToken } = await refreshTokenApi({
            refreshToken,
          });
          if (newToken) {
            headers?.set("authorization", `Bearer ${newToken}`);
            removeFromLocalStorage(undefined, "refreshing");
            return { headers };
          } else {
            throw new Error();
          }
        } else {
          removeFromLocalStorage(undefined, "refreshing");
          headers.append("authorization", `Bearer ${token}`);
          return { headers };
        }
      } catch (e) {
        console.error("Failed to renew session");
        removeFromLocalStorage(undefined, "refreshing");
        removeFromLocalStorage(undefined, "token");
      }
    } else {
      headers.append("authorization", `Bearer ${token}`);
      return {
        headers,
      };
    }
  },
  (error) => {
    if (error.getStatus() === 401) {
      removeFromLocalStorage("token");
      window.location.href = "/login?error=unauthorized";
    }
  }
);

// export const firebaseAuthApi = new Api(
//   Config.apiBaseurl,
//   async ({ headers = new Headers() }) => {
//     const { token } = (getFromLocalStorage() as any) || {};
//     const callToken = headers?.get("authorization");
//     const { currentUser } = firebase.auth();
//     if (!!callToken && (callToken || "").replace("Bearer ", "") !== token) {
//       return;
//     } else if (callToken) {
//       headers.append("authorization", `Bearer ${callToken}`);
//       return {
//         headers,
//       };
//     } else if (currentUser) {
//       const firebaseToken = await currentUser.getIdToken();
//       if (firebaseToken !== token) {
//         setOnLocalStorage({ token: firebaseToken });
//       }
//       headers.append("authorization", `Bearer ${firebaseToken}`);
//       return {
//         headers,
//       };
//     } else if (token) {
//       headers.append("authorization", `Bearer ${token}`);
//       return { headers };
//     }
//   },
//   async (error) => {
//     if (error.getStatus() === 401) {
//       removeFromLocalStorage("token");
//       await firebase.auth().signOut();
//       window.location.href = "/login?error=unauthorized";
//     }
//   }
// );
