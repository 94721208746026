import { ReactNode } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MetaTags from "../../components/MetaTags/MetaTags";
import { useHistory } from "react-router-dom";
import { CssBaseline, Grid } from "@mui/material";
import { Box } from "@mui/system";
import AppHeaderAdmin from "../../components/Header/AdminHeader";

type LayoutProps = {
  title?: string;
  children: ReactNode;
  disableBack?: boolean;
};

const Empty = ({ title, children, disableBack = false }: LayoutProps) => {
  const history = useHistory();
  return (
    <>
      <MetaTags title={title} />
      <div className="admin-layout">
        <CssBaseline />
        <AppHeaderAdmin />
        <Box component="main" className="admin-layout-box-container">
          {children}
        </Box>
      </div>
    </>
  );
};

export default Empty;
