import {
  api,
  customSecuredApi,
  // firebaseAuthApi
} from ".";
import Config from "../Config";
import {
  setOnLocalStorage,
  removeFromLocalStorage,
} from "../helpers/localstorage";
import { setCookie } from "../helpers/cookie";
import ErrorAPI from "../helpers/api";
import { AgencyType, UserAgencies } from "../models/product.types";

export const loginApi = async ({
  username,
  password,
}: {
  username: string;
  password: string;
}): Promise<{
  error?: boolean;
  message?: string | any;
  body?: UserAgencies;
}> => {
  const { payload, body } = await api.call<{ accessToken: string }>({
    method: "POST",
    path: `${Config.apis.auth.login}`,
    body: { username, password },
  });
  if (payload.ok) {
    const { accessToken } = body;
    setCookie("token", accessToken);
    // setCookie("refreshToken", refreshToken, 30);
    return { error: false, message: body };
  } else {
    // TODO handle error message
    return body;
  }
};

export const refreshTokenApi = async ({
  refreshToken,
}: {
  refreshToken: string;
}): Promise<{ token?: string; refreshToken: string }> => {
  const { body } = await api.call<{
    refreshToken: string;
    accessToken?: string;
  }>({
    method: "POST",
    path: `${Config.apis.auth.refreshToken}`,
    body: { refreshToken },
  });
  const { accessToken: newAccessToken, refreshToken: newRefreshToken } = body;
  if (newRefreshToken) {
    setCookie("refreshToken", newRefreshToken, 30);
    if (newAccessToken) {
      setOnLocalStorage({ token: newAccessToken });
    }
    return { token: newAccessToken, refreshToken: newRefreshToken };
  } else {
    // TODO handle error message
    throw new Error(body);
  }
};

export const logoutApi = async (forceRedirect = true) => {
  setCookie("token", "", -1);
  setCookie("role", "");
  removeFromLocalStorage("agencyId");
  removeFromLocalStorage("agencyName");
  removeFromLocalStorage("username");
  removeFromLocalStorage("shippingUnitCost");
  // await firebase.auth().signOut(); //for firebase
  if (forceRedirect) {
    window.location.href = "/";
  } else {
    return;
  }
};

export const getUserInfoApi = async () => {
  const { payload, body } = await customSecuredApi.call({
    path: `${Config.apis.auth.me}`,
    method: "GET",
  });
  if (payload.ok) {
    return body;
  } else {
    throw new ErrorAPI(payload.status, body);
  }
};

export const forgotPasswordApi = async ({
  email,
}: {
  email: string;
}): Promise<{ error: boolean; message?: string | any } | typeof ErrorAPI> => {
  const { payload, body }: { payload: Response; body?: string } =
    await api.call<{ accessToken: string }>({
      method: "POST",
      path: `${Config.apis.auth.sendResetPassword}`,
      body: { email },
    });
  if (payload.ok) {
    return { error: false };
  } else {
    // TODO handle error message

    throw new ErrorAPI(payload.status, body);
  }
};

export const resetPasswordApi = async (value: {
  userId: string;
  token: string;
  password: string;
}) =>
  api.call({
    method: "POST",
    path: `${Config.apis.auth.resetPassword}`,
    body: value,
  });

export const myAgency = async () =>
  customSecuredApi.call({
    method: "GET",
    path: `${Config.apis.auth.myAgency}`,
  });

export const meUser = async () =>
  customSecuredApi.call({
    method: "GET",
    path: `${Config.apis.auth.me}`,
  });
